import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../loader";
import { MdDeleteForever } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin2Line } from "react-icons/ri";

const MailList = () => {
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [emailToDelete, setEmailToDelete] = useState(null);

    useEffect(() => {
        const fetchEmails = async () => {
            try {
                const growerUser = JSON.parse(localStorage.getItem("grower_user"));
                const token = growerUser?.token;

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/growx/emails?type=draft`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Error fetching emails: ${response.statusText}`);
                }
                const result = await response.json();
                if (result.status) {
                    setEmails(result.data.emails);
                } else {
                    throw new Error(result.message || "Failed to retrieve emails.");
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEmails();
    }, []);

    const handleEmailClick = (email) => {
        setSelectedEmail(email);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedEmail(null);
    };

    const handleDeleteClick = (emailId) => {
        setEmailToDelete(emailId);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        const token = growerUser?.token;

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/growx/delete-email/${emailToDelete}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Error deleting email: ${response.statusText}`);
            }

            const result = await response.json();
            if (result.status) {
                setEmails(emails.filter((email) => email.id !== emailToDelete));
                toast.success("Email deleted successfully!");
            } else {
                throw new Error(result.message || "Failed to delete email.");
            }
        } catch (err) {
            console.error(err.message);
            toast.error("Failed to delete the email. Please try again.");
        } finally {
            setShowDeleteModal(false);
            setEmailToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteModal(false);
        setEmailToDelete(null);
    };

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center py-5"><Loader /></div>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="mailList-main ps-2 pe-2 ps-lg-5 pe-lg-2">
            <div className="email-lists">
                <div className="email-list-head border-bottom mb-3 d-flex justify-content-between align-items-center">
                    <p className="fs-5 fw-600">Email List</p>
                    <p className="fw-600">{emails.length} inbox</p>
                </div>
                {emails.map((email) => (
                    <div
                        key={email.id}
                        className="singleEmail border-bottom pb-1 mb-2"
                        onClick={() => handleEmailClick(email)}
                        style={{ cursor: "pointer" }}
                    >
                        <div className="d-flex justify-content-between align-items-start pb-2">
                            <div>
                                <p className="mb-0 fw-600">{email.to.name || "Unknown Recipient"}</p>
                                <p className="mb-0">{email.to.email}</p>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center position-relative">
                                <p className="email-date mb-0">
                                    {new Date(email.created_at).toLocaleDateString()}
                                </p>
                                <p className="email-status mb-0">
                                    {email.status.charAt(0).toUpperCase() + email.status.slice(1)}
                                </p>
                                <button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleDeleteClick(email.id);
                                    }}
                                    type="button"
                                    className="border-0 n-del-btn p-1 position-absolute"
                                >
                                    <MdDeleteForever />
                                </button>
                            </div>
                        </div>
                        <p className="email-subject mb-0 fw-600">{email.subject}</p>
                    </div>
                ))}
            </div>

            {/* Email Details Modal */}
            <Modal show={showModal} onHide={handleCloseModal} centered size="md">
                <Modal.Header className="border-0 d-flex justify-content-end pb-0">
                    <button className="bg-transparent border-0" onClick={handleCloseModal}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="15" cy="15" r="15" fill="#001C1F" />
                            <path d="M22 8.35352L8 22.3535" stroke="white" strokeLinecap="round" />
                            <path d="M8.64648 8L22.2653 22.371" stroke="white" strokeLinecap="round" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {selectedEmail ? (
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Email Details</h5>
                            </div>
                            <div className="modal-body">
                                <p><strong>To:</strong> {selectedEmail.to.email} ({selectedEmail.to.name})</p>
                                <p><strong>From:</strong> {selectedEmail.from.email} ({selectedEmail.from.name})</p>
                                <p><strong>Subject:</strong> {selectedEmail.subject}</p>
                                <p><strong>Body:</strong></p>
                                <p>{selectedEmail.body}</p>
                                {selectedEmail.attachments.count > 0 && (
                                    <div>
                                        <p><strong>Attachments:</strong></p>
                                        {selectedEmail.attachments.urls.map((url, index) => (
                                            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                                                Attachment {index + 1}
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center py-5"><Loader /></div>
                    )}
                </Modal.Body>
            </Modal>

            {/* Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={handleCancelDelete} centered>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="invisible">Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <RiDeleteBin2Line className="fs-1 text-danger" />
                    </div>
                    <p className="pt-4">Are you sure you want to delete this email?</p>
                </Modal.Body>
                <Modal.Footer className="border-0 d-flex justify-content-center">
                    <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                    <button className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MailList;
