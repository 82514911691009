
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import { FaUserTie } from "react-icons/fa";

const GOOGLE_MAPS_API_KEY = "AIzaSyALtPY2K3RllnOU4sMd_iTH6cLsKX1mEyA";

const UserMap = () => {
  const [users, setUsers] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["maps"],
  });

  const fetchUsers = async () => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const token = growerUser?.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/growx/users?role=grow_master`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status) {
        setUsers(data.data);
      } else {
        console.error("Failed to fetch users:", data.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Geolocation error:", error);
      }
    );
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "500px" }}
      center={currentLocation || { lat: 37.421998, lng: -122.084 }}
      zoom={currentLocation ? 5 : 5}
    >
      {currentLocation && (
        <Marker
          position={currentLocation}
          title="Your Location"
          icon={{
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          }}
        />
      )}

      {users
        .filter(
          (user) =>
            user.location &&
            user.location.latitude !== null &&
            user.location.longitude !== null
        )
        .map((user) => (
          <Marker
            key={user.id}
            position={{
              lat: parseFloat(user.location.latitude),
              lng: parseFloat(user.location.longitude),
            }}
            title={user.name || user.email}
            onClick={() => setSelectedUser(user)}
          />
        ))}
      {selectedUser && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedUser.location.latitude),
            lng: parseFloat(selectedUser.location.longitude),
          }}
          onCloseClick={() => setSelectedUser(null)}
        >
          <div className="infoBoxMap">
            <div>
            <div className="mb-2">
              <FaUserTie className="fs-1" style={{ color: "#00898d" }} />
            </div>
            <h5 className="text-uppercase mb-0">{selectedUser.name || "No Name"}</h5>
            <p className="mb-2 fw-600">{selectedUser.role}</p>
            <p><strong>Email:</strong> {selectedUser.email}</p>

            <div className="d-flex gap-3">
              <p><strong>Phone:</strong> {selectedUser.phone || "Not Available"}</p>
              <p><strong>Arrival Time:</strong> {selectedUser.phone || "Not Available"}</p>
            </div>
          </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default UserMap;
