import React, { useState } from 'react';
import DelIcon from "../../assets/images/delete1.png";
import DotsIcon from "../../assets/images/dots1.png";
import NewsIcon from "../../assets/images/news1.png";
import HappinessIcon from "../../assets/images/happiness1.png";
import FileIcon from "../../assets/images/attachments1.png";
import CalenderIcon from "../../assets/images/whitecalneder.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MailList from '../mailList';
import { FaPen } from "react-icons/fa6";
import { IoMdArrowBack, IoMdArrowRoundBack } from "react-icons/io";


const MailComponent = () => {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: '',
        file: null,
    });

    const [errors, setErrors] = useState({});
    const [isComposeMode, setIsComposeMode] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0] });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email format.';
        }
        if (!formData.subject) {
            newErrors.subject = 'Subject is required.';
        }
        if (!formData.message) {
            newErrors.message = 'Message is required.';
        }
        if (!formData.file) {
            newErrors.file = 'Please attach a file.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        const token = growerUser?.token;

        e.preventDefault();
        if (validateForm()) {
            try {
                const formDataPayload = new FormData();
                formDataPayload.append("subject", formData.subject);
                formDataPayload.append("body", formData.message);
                formDataPayload.append("type", "draft");
                formDataPayload.append("from_email", formData.email);
                formDataPayload.append("receiver_id", "51");
                formDataPayload.append("to_email", "growmaster@gmail.com");
                if (formData.file) {
                    formDataPayload.append("attachments", formData.file);
                }

                // Make the API request
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/growx/send-email`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formDataPayload,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                console.log("API Response:", result);

                setFormData({
                    email: '',
                    subject: '',
                    message: '',
                    file: null,
                });

                setErrors({});
                toast.success("Email sent successfully!");
                setIsComposeMode(false);
            } catch (error) {
                console.error("Error sending email:", error);
                toast.error("Failed to send email. Please try again.");
            }
        }
    };

    return (
        <>
            <div className='px-2 px-lg-5 pb-4'>
                {isComposeMode ?
                    <button
                        className='px-3 py-2 compose-btn'
                        onClick={() => setIsComposeMode(false)}
                    >
                        <IoMdArrowRoundBack className='fs-5' /> Inbox
                    </button>

                    : <button
                        className='px-3 py-2 compose-btn'
                        onClick={() => setIsComposeMode(true)}
                    >
                        <FaPen /> Compose
                    </button>
                }
            </div>
            <ToastContainer />
            {isComposeMode ? (
                <form onSubmit={handleSubmit}>
                    <div className="mail-main px-lg-5">
                        <div className="d-flex gap-2 align-items-center border-buttom position-relative">
                            <p className="mb-0 fs-16 fw-500">From:</p>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="border-0 outline-none bg-transparent text-black py-2 px-3"
                                placeholder="example@gmail.com"
                            />
                            {errors.email && <p className="validate-error text-danger position-absolute">{errors.email}</p>}
                        </div>

                        <div className="d-flex gap-2 align-items-center border-buttom pt-3 pb-3 position-relative">
                            <input
                                type="text"
                                name="subject"
                                value={formData.subject}
                                onChange={handleInputChange}
                                className="border-0 w-100 text-start bg-transparent text-black py-2 px-1 outline-none"
                                placeholder="Your Subject here"
                            />
                            {errors.subject && <p className="text-danger validate-error position-absolute">{errors.subject}</p>}
                        </div>

                        <div className='position-relative pt-4'>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                className="outline-none fs-18 my-4 w-100 border-0"
                                rows="6"
                                placeholder="Enter your Message here"
                            ></textarea>
                            {errors.message && <p className="text-danger validate-error position-absolute bottom-0">{errors.message}</p>}
                        </div>

                        <div className="d-flex align-items-center mb-5 position-relative">
                            <input
                                type="file"
                                name="file"
                                onChange={handleFileChange}
                                className="form-control"
                            />
                            {errors.file && <p className="text-danger validate-error position-absolute">{errors.file}</p>}
                            {formData.file && <p className="ms-3">{formData.file.name}</p>}
                        </div>

                        <div className="mail-footer d-flex justify-content-between align-content-center">
                            <div>
                                {/* <button type="button" className="bg-transparent border-0 p-2">
                                    <img src={DelIcon} alt="delete" />
                                </button>
                                <button type="button" className="bg-transparent border-0 p-2">
                                    <img src={DotsIcon} alt="dots" />
                                </button> */}
                            </div>
                            <div>
                                {/* <button type="button" className="bg-transparent border-0 p-2">
                                    <img src={NewsIcon} alt="news" />
                                </button>
                                <button type="button" className="bg-transparent border-0 p-2">
                                    <img src={HappinessIcon} alt="happiness" />
                                </button>
                                <button type="button" className="bg-transparent border-0 p-2">
                                    <img src={FileIcon} alt="file" />
                                </button> */}
                                <button type="submit" className="border-0 py-2 px-3 send-btns ms-4">
                                    Send now
                                    <span className="send-border mx-2"></span>
                                    <img src={CalenderIcon} alt="calendar" />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            ) : (
                <MailList />
            )}
        </>
    );
};

export default MailComponent;
